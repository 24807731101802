import React, {useContext, useEffect} from 'react'
import Text from '@component/Text/Text'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'next-i18next'
import ScreenLoginButtonLogin from '@feature/common/components/LoginModal/component/ScreenLoginButtonLogin'
import WizButton from '@component/buttons/WizButton'
import TagText from '@component/Text/TagText'
import useNavigation from '@hook/useNavigation'
import ScreenLoginReferralBanner from '@feature/common/components/LoginModal/screen/ScreenLogin/ScreenLoginReferralBanner'
import {isNotEmpty} from '@util/strings'
import useLoginModal from '@feature/common/components/LoginModal/hooks/useLoginModal'
import {LoginModalContext} from '../../../../../../../pages/login/index.page'
import {useLoginModalStore} from '@store/LoginModalStore'
import {IS_LOGIN_FAILED_STATE_KEY} from '@constant/key'
import {useLocalStorage} from 'usehooks-ts'
import {createLog} from '@util/logs'

interface IProps extends ILoginModalProps {}

const ScreenLogin: React.FC<IProps> = ({closeModal}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [isLoginFailed] = useLocalStorage(IS_LOGIN_FAILED_STATE_KEY, false)
    const {initRoute} = useLoginModalStore(state => ({
        initRoute: state.initRoute,
    }))
    const {goPrivacyPolicy, goTermsAndConditions} = useNavigation()

    const {appliedReferral} = useContext(LoginModalContext)

    const {redirectAfterLogin} = useLoginModal()

    const callbackLoginSuccess = () => {
        closeModal()
        redirectAfterLogin()
        createLog('event', 'login_modal_close')
    }

    useEffect(() => {
        if (!initRoute) return
        navigate(initRoute)
    }, [initRoute])

    const onClickOtherLogin = () => {
        createLog('event', 'login_click_other_method_button')
        navigate('/login_other')
    }

    const onClickPrivacyPolicy = () => {
        createLog('event', 'login_click_privacy_policy_button')
        goPrivacyPolicy(true)
    }
    const onClickTermsAndConditions = () => {
        createLog('event', 'login_click_terms_and_conditions_button')
        goTermsAndConditions(true)
    }

    return (
        <LoginModalLayout closeModal={closeModal} title={t('loginModal.login.page.title')}>
            {isNotEmpty(appliedReferral) && <ScreenLoginReferralBanner className={'mb-[10px]'} />}
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02'}>{t('loginModal.login.page.desc')}</Text>
            <div className={'grid mt-[10px] gap-y-[10px]'}>
                <ScreenLoginButtonLogin methodType={'google'} loginSuccessCallback={callbackLoginSuccess} />
                <ScreenLoginButtonLogin methodType={'binance'} loginSuccessCallback={callbackLoginSuccess} />
                <ScreenLoginButtonLogin methodType={'email'} dataCy={'btn_login_email'} />
                {isLoginFailed && (
                    <Text className={'text-red_shade dark:text-dark_red_shade text-body2 text-center'}>
                        {t('loginModal.login.failed.desc')}
                    </Text>
                )}
                <WizButton
                    text={t('loginModal.login.loginMethod.more.text')}
                    buttonType={'gray'}
                    onClick={onClickOtherLogin}
                />
            </div>
            <TagText
                className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[10px]'}
                funcList={[
                    {id: 'privacy', func: onClickPrivacyPolicy},
                    {id: 'terms_and_conditions', func: onClickTermsAndConditions},
                ]}>
                {t('loginModal.login.termDescription')}
            </TagText>
        </LoginModalLayout>
    )
}

export default ScreenLogin
