export interface ApiRes<T> {
    data: T
    status: number
    error?: string
    isManager?: boolean
    pageInfo?: IPageInfo
}

export interface ApiError {
    status: number
    error?: string
}
export interface IPageInfo {
    current_page?: number
    page_size?: number
    total_count?: number
    total_page?: number
}

export enum ApiStatus {
    Success = 200,

    UnknownServerError = 5000,
    Unauthorized = 5001,
    EmailAlreadyRegistered = 5002,
    PasswordLessThanRequired = 5003,
    SomethingMissing = 5004,
    WrongConfirmationCode = 5005,
    WrongAccessToken = 5006,
    EmailNotRegistered = 5007,
    EmailAlreadyConfirmed = 5008,
    WrongPassword = 5009,
    DuplicateAPIKey = 5010,
    WrongEmailOrPassword = 5011,
    NameAlreadyRegistered = 5012,
    UserDeleted = 5013,
    ContentMissing = 5014,
    NoLeaguePresent = 5015,
    CantUnfollowLOT = 5016,
    ReferralCodeMissing = 5017,
    EmailBelongsToAnotherAccount = 5018,
    PhoneNumberBelongsToAnotherAccount = 5019,
    ActionRequiresSubscription = 5020,
    FollowNumberFullUpgradeSubscription = 5021,
    UnfollowedManyTimesBlocked = 5022,
    NotFollowingTheUser = 5023,
    PrivateDataNotAllowed = 5024,
    OverdrawnPoints = 5025,
    CopyBotNotAllowed = 5026,
    GuildDeleted = 5027,
    LeagueConditionNotMet = 5028,
    CantUpdateAPIKeyDifferentAccount = 5029,
    CantUpdateAPIKeyExchangeNotSupported = 5030,
    BotBalanceLessThanMinimum = 5031,
    BotKeyCantDelete = 5032,
    BotCompanyError = 5033,

    FunctionNotSupportedYetForGivenExchange = 5035,
    InvoicePaymentVerifyUnknownError = 5036,
    KeyHasNoPermissionForGivenOperation = 5037,
    UserNicknameChangedWithinMonth = 5038,

    LegueRegisterByPassed = 5040,
    LeagueMinimumBalanceNotMet = 5041,
    LeagueRemovedDueToKeyDelete = 5042,
    ConfirmationCodeExpired = 5043,
    AccountHasBalanceCantDelete = 5044,
    AccountIsBotLeaderCantDelete = 5045,

    ErrorKeyMissingAllRequiredPermissions = 5046,
    ErrorInvalidApiKey = 5047,
    ErrorKeyNotFound = 5048,

    ErrorWithdrawingAmountExceeded = 5049,
    ErrorInvalidRequest = 5050,
    ErrorInvalidWithdrawAddress = 5051,
    ErrorUnsupportedChain = 5052,
    WrongTOTP = 5053,
    OTPExistsResetFirst = 5054,
    WithdrawExpired = 5055,
    WithdrawWrongVerificationCode = 5056,
    WithdrawNotSupported = 5057, //example withdrawing non crypto wallet
    EmailRequiredButMissing = 5058,
    WrongSignature = 5059,
    HasUnpaidInvoiceCantStartBot = 5060,
    WithdrawAlreadyProcessingCantCancel = 5061,

    AccountHasPendingInvoiceCantDelete = 5062,

    ErrorNotFound = 5062,
    UnknownErrorFromExchange = 5070,

    ErrorBotRatioDifferenceHigh = 6001,
    ErrorCopierKeyInvalid = 6002,
    ErrorLeaderKeyInvalid = 6003,
    ErrorBotPositionMismatch = 6004,
    ErrorBotUnknown = 6005,
    ErrorBotOpenOrderFound = 6006,
    ErrorKeyIsRegisteredInAnotherBot = 6007,
    ErrorKeyIsNotCopyLeader = 6008,
    ErrorCopyLeaderConditionsNotMet = 6009,
    ErrorBotBalanceNotSufficient = 6010,
    ErrorBotDoesntExist = 6011,
    ErrorLeaderCopierCountReached = 6012,
    ErrorBannedFromBeingLeader = 6013,
    ErrorLeaderKeyCantBeABot = 6014,
    ErrorLeaderLockedForPeriod = 6015,
}
