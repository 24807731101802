import React from 'react'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import Text from '@component/Text/Text'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import {useTranslation} from 'next-i18next'
import WizButton from '@component/buttons/WizButton'
import TagSpan from '@component/Text/TagSpan'
import useNavigation from '@hook/useNavigation'
import img_welcome_mobile from '@image/img_login_modal_side_3_mo.png'
import WizImage from '@component/images/WizImage'
import WizIconButton from '@component/buttons/WizIconButton'
import CloseIcon from '@svg/common/ic_close_bold.svg'
import img_welcome_register_01 from '@image/landing/img_welcome_register_01.png'
import img_welcome_register_01_dark from '@image/landing/img_welcome_register_01_dark.png'
import img_welcome_register_02 from '@image/landing/img_welcome_register_02.png'
import img_welcome_register_02_dark from '@image/landing/img_welcome_register_02_dark.png'
import img_welcome_register_03 from '@image/landing/img_welcome_register_03.png'
import img_welcome_register_03_dark from '@image/landing/img_welcome_register_03_dark.png'
import img_welcome_register_04 from '@image/landing/img_welcome_register_04.png'
import img_welcome_register_04_dark from '@image/landing/img_welcome_register_04_dark.png'
import useDark from '@hook/useDark'
import {createLog} from '@util/logs'

interface IProps extends ILoginModalProps {}

const ScreenRegisterWelcome: React.FC<IProps> = ({closeModal}) => {
    const {t} = useTranslation()
    const {isRenderDark} = useDark()
    const {goLeaderboard, goApi} = useNavigation()

    const sendToLeaderboard = () => {
        createLog('event', 'login_welcome_join_leaderboard')
        closeModal()
        goLeaderboard()
    }
    const sendToApi = () => {
        createLog('event', 'login_welcome_connect_exchange')
        closeModal()
        goApi()
    }

    return (
        <>
            <div className={'hidden sm:block'}>
                <LoginModalLayout
                    closeModal={sendToLeaderboard}
                    title={t('')}
                    sideImageType={'welcome_join'}
                    containerClassName={'mt-0'}
                    hideBackButton={true}>
                    <div className={'bg-[#6A4FA4] mt-[-31px] pt-[40px] mb-[56px] mx-[-20px] px-[30px]'}>
                        <Text className={'text-h3 text-white text-center pt-[15px]'}>
                            {t('loginModal.sideImage.type.welcomeJoin.title')}
                        </Text>
                        <Text className={'text-body2 text-white text-center pb-[10px]'}>
                            {t('loginModal.sideImage.type.welcomeJoin.desc')}
                        </Text>
                        <WizImage src={img_welcome_mobile} width={300} height={200} containerClassName={'mx-auto'} />
                    </div>
                    <Text className={'text-body1 text-gray02 dark:text-dark_gray02 font-bold'}>
                        {t('loginModal.registerWelcome.title')}
                    </Text>
                    <Text className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                        {t('loginModal.registerWelcome.tips.title')}
                    </Text>

                    <ul className={'list-disc list-outside ml-[25px] mt-[10px]'}>
                        <li>
                            <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                                {t('loginModal.registerWelcome.tips.option1')}
                            </TagSpan>
                        </li>
                        <li className={'mt-[20px]'}>
                            <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                                {t('loginModal.registerWelcome.tips.option2')}
                            </TagSpan>
                        </li>
                        <li className={'mt-[20px]'}>
                            <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                                {t('loginModal.registerWelcome.tips.option3')}
                            </TagSpan>
                        </li>
                    </ul>

                    <WizButton
                        buttonType={'primary'}
                        text={t('loginModal.registerWelcome.submit.connectExchange.text')}
                        className={'mt-[10px] w-full'}
                        onClick={sendToApi}
                    />
                    <WizButton
                        buttonType={'gray'}
                        text={t('loginModal.registerWelcome.submit.goLeaderboard.text')}
                        className={'mt-[10px] w-full'}
                        onClick={sendToLeaderboard}
                    />
                </LoginModalLayout>
            </div>
            <div className={'block sm:hidden py-[20px] px-[30px] bg-white dark:bg-bg_dark_popup rounded-[5px] w-full'}>
                <div className={'flex justify-end'}>
                    <WizIconButton onClick={sendToLeaderboard}>
                        <CloseIcon width={30} heigth={30} className={'fill-gray02 dark:fill-dark_gray02'} />
                    </WizIconButton>
                </div>
                <div>
                    <Text className={'text-h3 text-center text-gray01 dark:text-dark_gray01'}>
                        {t('loginModal.registerWelcome.mobile.title')}
                    </Text>
                    <Text
                        className={
                            'pt-[10px] text-body2 text-gray02 dark:text-dark_gray02 w-[60%] mx-auto text-center'
                        }>
                        {t('loginModal.registerWelcome.mobile.desc')}
                    </Text>
                    <div className={'mt-[20px] grid grid-cols-2 gap-[10px]'}>
                        <div className={'bg-primary_tint02 dark:bg-dark_primary_tint02 h-[150px] '}>
                            <Text
                                className={
                                    'text-body3 text-gray02 dark:text-dark_gray02 text-center px-[30px] pt-[15px]'
                                }>
                                {t('loginModal.registerWelcome.mobile.step1')}
                            </Text>
                            <WizImage
                                src={isRenderDark ? img_welcome_register_01_dark : img_welcome_register_01}
                                width={290}
                                imageClassName={'mx-auto'}
                            />
                        </div>
                        <div className={'bg-blue_tint02 dark:bg-dark_blue_tint02 h-[150px] '}>
                            <Text
                                className={
                                    'text-body3 text-gray02 dark:text-dark_gray02 text-center px-[30px] pt-[15px]'
                                }>
                                {t('loginModal.registerWelcome.mobile.step2')}
                            </Text>
                            <WizImage
                                src={isRenderDark ? img_welcome_register_02_dark : img_welcome_register_02}
                                width={290}
                                imageClassName={'mx-auto'}
                            />
                        </div>
                        <div className={'bg-blue_tint02 dark:bg-dark_blue_tint02 h-[150px] '}>
                            <Text
                                className={
                                    'text-body3 text-gray02 dark:text-dark_gray02 text-center px-[30px] pt-[15px]'
                                }>
                                {t('loginModal.registerWelcome.mobile.step3')}
                            </Text>
                            <WizImage
                                src={isRenderDark ? img_welcome_register_03_dark : img_welcome_register_03}
                                width={290}
                                imageClassName={'mx-auto'}
                            />
                        </div>
                        <div className={'bg-primary_tint02 dark:bg-dark_primary_tint02 h-[150px] '}>
                            <Text
                                className={
                                    'text-body3 text-gray02 dark:text-dark_gray02 text-center px-[30px] pt-[15px]'
                                }>
                                {t('loginModal.registerWelcome.mobile.step4')}
                            </Text>
                            <WizImage
                                src={isRenderDark ? img_welcome_register_04_dark : img_welcome_register_04}
                                width={290}
                                imageClassName={'mx-auto'}
                            />
                        </div>
                    </div>
                </div>
                <div className={'mt-[23px]'}>
                    <WizButton
                        onClick={sendToApi}
                        buttonType={'primary'}
                        className={'w-full'}
                        text={t('loginModal.registerWelcome.submit.connectExchange.text')}
                    />
                </div>
            </div>
        </>
    )
}

export default ScreenRegisterWelcome
