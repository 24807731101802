import React, {useContext, useEffect, useMemo, useState} from 'react'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import Text from '@component/Text/Text'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import {useTranslation} from 'next-i18next'
import WizInputText, {InputState} from '@component/inputs/WizInputText'
import WizButton from '@component/buttons/WizButton'
import {useNavigate} from 'react-router-dom'
import TagText from '@component/Text/TagText'
import {RegisterContext} from '@feature/common/components/LoginModal/routes/RegisterRoutes/RegisterRoutes'
import useSnackbar from '@hook/useSnackbar'
import {isEmpty, isNotEmpty} from '@util/strings'
import {apiEditReferral} from '@api/auths/register/editReferral'
import useNavigation from '@hook/useNavigation'
import {createLog} from '@util/logs'
import {useRouter} from 'next/router'

interface IProps extends ILoginModalProps {}

const ScreenRegisterReferral: React.FC<IProps> = ({closeModal}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const router = useRouter()

    const {referral, setReferral} = useContext(RegisterContext)
    const [isLoading, setIsLoading] = useState(false)

    const {showErrorSnackbar} = useSnackbar()
    const {goLeaderboard} = useNavigation()

    const [inputState, setInputState] = useState<InputState>(InputState.DEFAULT)
    const [inputErrorMessage, setInputErrorMessage] = useState('')

    const isDisableSubmitButton = useMemo(() => isEmpty(referral), [referral])

    useEffect(() => {
        if (router?.query?.referral) {
            setReferral(router?.query?.referral.toString())
        }
    }, [router?.query?.referral])

    const onClickSubmit = async () => {
        if (isDisableSubmitButton) return
        createLog('event', 'login_email_register_referral_submit')

        setIsLoading(true)
        const {data, status, error} = await apiEditReferral(referral)
        if (data) {
            navigate('/register_done')
        } else {
            if (status === 5017) {
                createLog('error', 'login_email_register_password_submit_failed_wrong_code')
                setInputState(InputState.ERROR)
                setInputErrorMessage(t('loginModal.registerReferral.submit.res.fail.wrongCode'))
            } else {
                createLog('error', 'login_email_register_password_submit_failed')
                showErrorSnackbar(error)
            }
        }
        setIsLoading(false)
    }

    const onClickSkip = async () => {
        createLog('event', 'login_email_register_referral_skip')
        navigate('/register_done')
    }

    return (
        <LoginModalLayout
            closeModal={() => {
                closeModal()
                goLeaderboard()
            }}
            title={t('loginModal.registerReferral.page.title')}
            hideBackButton={true}>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                {t('loginModal.registerReferral.desc')}
            </Text>
            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 mt-[10px]'}>
                {t('loginModal.registerReferral.referral.label')}
            </Text>
            <WizInputText
                className={'mt-[5px]'}
                text={referral}
                state={inputState}
                errorDescription={inputErrorMessage}
                autoFocus={true}
                onChanged={text => {
                    setReferral(text)
                    setInputState(InputState.DEFAULT)
                }}
                placeholder={t('loginModal.registerReferral.referral.input.placeholder')}
                onPressEnter={onClickSubmit}
            />
            <WizButton
                buttonType={'primary'}
                text={t('loginModal.registerReferral.submit.continue.text')}
                className={'mt-[10px] w-full'}
                disabled={isDisableSubmitButton}
                isLoading={isLoading}
                onClick={onClickSubmit}
            />
            <WizButton
                buttonType={'gray'}
                text={t('loginModal.registerReferral.submit.skip.text')}
                className={'mt-[10px] w-full'}
                onClick={onClickSkip}
            />
            <TagText className={'text-body2 text-gray02 dark:text-dark_gray02 mt-[10px] text-center'}>
                {t('loginModal.registerReferral.submit.skip.desc')}
            </TagText>
        </LoginModalLayout>
    )
}

export default ScreenRegisterReferral
