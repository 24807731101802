import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {fetchExchanges} from '@api/core/exchange/exchange'
import {equalString} from '@util/strings'
import {IExchange} from '@type/exchange'
import useSnackbar from '@hook/useSnackbar'
import {useTranslation} from 'next-i18next'
import {TIME_HOUR} from '@constant/time'

const useQueryGetExchanges = () => {
    const {showErrorSnackbar} = useSnackbar()
    const {t} = useTranslation()
    const {data, refetch, isLoading, isError, isFetching, isSuccess} = useQuery({
        queryKey: QueryCacheKeys.exchange.getList(),
        staleTime: TIME_HOUR * 1000,
        keepPreviousData: true,
        queryFn: async () => {
            const {data, error} = await fetchExchanges()
            if (error) {
                // showErrorSnackbar(error)
                showErrorSnackbar(t('error.code.5000'))
                return
            }
            if (data) return data
            return [] as IExchange[]
        },
    })

    const getExchange = (name: string) => {
        if (!data || data?.length === 0) {
            return undefined
        } else {
            return data.find(item => equalString(item.name, name))
        }
    }

    return {data, refetch, isLoading, isError, getExchange, isFetching, isSuccess}
}

export default useQueryGetExchanges
