import React, {useContext, useMemo} from 'react'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import WizIconButton from '@component/buttons/WizIconButton'
import LeftIcon from '@svg/common/ic_btn_back.svg'
import Text from '@component/Text/Text'
import {useLocation, useNavigate} from 'react-router-dom'
import {equalString} from '@util/strings'
import LoginModalSideImage, {
    LoginSideImageType,
} from '@feature/common/components/LoginModal/layout/image/LoginModalSideImage'
import {LoginModalContext} from '../../../../../../pages/login/index.page'
import IconModalClose from '@svg/common/ic_modal_close.svg'
import WizIcon from '@component/images/WizIcon'
import {createLog} from '@util/logs'

interface IProps extends ILoginModalProps {
    children?: React.ReactNode
    sideImageType?: LoginSideImageType
    hideBackButton?: boolean
    title?: string
    containerClassName?: string
}

const LoginModalLayout = ({
    title = 'League of Traders',
    children,
    sideImageType = 'say_hello',
    hideBackButton = false,
    closeModal,
    containerClassName,
}: IProps) => {
    const navigate = useNavigate()
    const location = useLocation()

    const isHideBack = useMemo(() => equalString(location.pathname, '/') || hideBackButton, [location, hideBackButton])

    const {isModal, appliedReferral} = useContext(LoginModalContext)

    const sideImageBackgroundColor = useMemo(() => {
        switch (sideImageType) {
            case 'say_hello':
                return `${appliedReferral ? '' : 'bg-primary'}`
            case 'safety_first':
                return 'bg-[#3761CC]'
            case 'welcome_join':
                return 'bg-[#6A4FA5]'
        }
    }, [sideImageType])

    const goBack = () => {
        createLog('event', 'login_other_method_click_back_button')
        navigate(-1)
    }

    return (
        <div
            className={'grid grid-cols-2 sm:grid-cols-1 bg-white dark:bg-bg_dark_popup rounded-[5px] w-full'}
            data-cy={'login_modal'}>
            <div
                className={`px-[30px] pt-[30px] pb-[50px] sm:px-[20px] sm:py-[18px] bg-white dark:bg-bg_dark_popup rounded-tl-[5px] rounded-bl-[5px] ${
                    sideImageType === 'welcome_join' && 'sm:my-[44px]'
                }`}>
                <div className={'flex items-center gap-x-[10px]'}>
                    <WizIconButton className={`${isHideBack ? 'hidden' : 'block'}`} onClick={goBack}>
                        <LeftIcon className={`w-[16px] transition-color fill-gray02 dark:fill-dark_gray02`} />
                    </WizIconButton>
                    <Text className={`text-h3 flex-1 text-gray01 dark:text-dark_gray01`} enablePreWhiteSpace={false}>
                        {title}
                    </Text>
                    {isModal === true && (
                        <WizIconButton onClick={closeModal} className={'hidden sm:block'}>
                            {sideImageType !== 'welcome_join' ? (
                                <WizIcon backgroundColorClassName={'p-[4px] hover:bg-gray07 dark:hover:bg-dark_gray07'}>
                                    <IconModalClose className={'w-[20px] fill-gray02 dark:fill-dark_gray02'} />
                                </WizIcon>
                            ) : (
                                <IconModalClose className={'w-[20px] fill-white'} />
                            )}
                        </WizIconButton>
                    )}
                </div>
                <div className={`mt-[20px] ${containerClassName}`}>{children}</div>
            </div>
            <div className={`rounded-r overflow-hidden sm:hidden ${sideImageBackgroundColor}`}>
                {isModal === true && (
                    <WizIconButton onClick={closeModal}>
                        <IconModalClose className={'w-[20px] fill-white absolute right-[20px] top-[20px]'} />
                    </WizIconButton>
                )}
                <LoginModalSideImage type={sideImageType} />
            </div>
        </div>
    )
}

export default LoginModalLayout
