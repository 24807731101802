import type {AppProps} from 'next/app'
import '@style/globals.css'
import '@style/tailwind.css'
import '@style/colorStyle.css'
import '@style/reset.css'
import '@style/event/event.css'
import '@font/createGlobalStyle.css'
import '@lib/datadog'
import 'react-toastify/dist/ReactToastify.css'
import React, {useState} from 'react'
import {appWithTranslation, useTranslation} from 'next-i18next'
import Loader from '@component/core/Loader'
import Head from 'next/head'
import {Hydrate, QueryClient, QueryClientProvider} from '@tanstack/react-query'
import ModalContainer from '@component/core/ModalContainer'
import LoginExtender from '@component/core/LoginExtender'
import LotAppBanner from '@component/appBanner/LotAppBanner'
import PresetLoader from '@component/core/PresetLoader'
import {useNats} from '@hook/useNats'
import {ToastContainer} from 'react-toastify'
import useInitDarkMode from '@hook/useInitDarkMode'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'
import dynamic from 'next/dynamic'
import GoogleAnalytics from '@component/google/GoogleAnalytics'

const DataDogIdentification = dynamic(() => import('@component/core/DataDogIdentification'), {ssr: false})

const MyApp = ({Component, pageProps}: AppProps) => {
    const {t} = useTranslation()

    const [queryClient] = useState(() => new QueryClient())
    const {} = useInitDarkMode()
    const {} = useNats()
    
    useIsomorphicLayoutEffect(() => {
        const isMobile = /Android|BlackBerry|Nokia/i.test(navigator.userAgent)
        const isWindow = navigator.userAgent?.includes('Win')

        if (isMobile) {
            document?.documentElement?.classList?.add('mobile')
        } else if (isWindow) {
            document?.documentElement?.classList?.add('window')
        } else {
            document?.documentElement?.classList?.add('mac')
        }
    }, [])

    return (
        <>
            <Head>
                <title>{t('global.title')}</title>
                <meta name="apple-itunes-app" content="app-id=1462023165" />
            </Head>
            <GoogleAnalytics />
            <LoginExtender />
            <QueryClientProvider client={queryClient}>
                <Hydrate state={pageProps.dehydratedState}>
                    <>
                        <Loader />
                        <ModalContainer />
                        <PresetLoader />
                        <LotAppBanner />
                        <Component {...pageProps} />
                        <ToastContainer
                            position={'bottom-center'}
                            hideProgressBar={true}
                            autoClose={3000}
                            pauseOnFocusLoss={false}
                            toastClassName={'!bg-transparent !shadow-none !p-0 !m-0 !h-min max-w-[400px] w-full !fixed'}
                            bodyClassName={'text-white !p-0 !m-0 !h-min'}
                            className={'!w-full max-w-[400px] min-h-[54px] !p-0 !bottom-[50px] !z-snackbar'}
                            closeButton={false}
                        />
                    </>
                </Hydrate>
                <DataDogIdentification />
            </QueryClientProvider>
        </>
    )
}

export default appWithTranslation(MyApp)
