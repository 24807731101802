import React from 'react'
import IconClose from '@svg/common/ic_close.svg'
import img_lot from '@image/ic_app.webp'
import WizImage from '@component/images/WizImage'
import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import {useTranslation} from 'next-i18next'
import {useAppBannerStore} from '@store/appBannerStore'
import {useRouter} from 'next/router'

interface IProps {
    className?: string
}

const LotAppBanner: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const router = useRouter()

    const {setIsShowAppBanner} = useAppBannerStore()

    function checkInstallApp() {
        function clearTimers() {
            clearInterval(check)
            clearTimeout(timer)
        }

        function isHideWeb() {
            if (document.hidden) {
                clearTimers()
            }
        }

        const check = setInterval(isHideWeb, 100)

        const timer = setTimeout(function () {
            redirectStore()
        }, 1500)
    }

    const redirectStore = () => {
        const ua = navigator.userAgent.toLowerCase()
        location.href = ua.indexOf('android') > -1 ? t('download.link.android') : t('download.link.apple')
    }

    return (
        <div
            onClick={() => {
                router?.push('lot://')
                checkInstallApp()
            }}
            className={`mobile:flex hidden p-[10px] bg-white dark:bg-bg_dark_gnb gap-x-[10px] items-center`}>
            <IconClose
                width={14}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    setIsShowAppBanner(false)
                }}
                className={'cursor-pointer fill-gray02 dark:fill-dark_gray02'}
            />
            <WizImage src={img_lot} width={35} height={35} />
            <div className={'flex-1'}>
                <Text className={'text-ti3 text-gray01 dark:text-dark_gray01'}>{t('appBanner.title')}</Text>
                <Text className={'text-body_comm text-gray02 dark:text-dark_gray02'}>{t('appBanner.desc')}</Text>
            </div>
            <WizButton
                text={t('appBanner.button.text')}
                className={'px-[10px] py-[5px]'}
                textClassName={'text-ti3 text-white'}
                buttonType={'primary'}
                size={'small'}
                onClick={() => {
                    router?.push('lot://')
                    checkInstallApp()
                }}
            />
        </div>
    )
}

export default LotAppBanner
