import {initializeApp} from '@firebase/app'
import {getAnalytics, logEvent, isSupported} from '@firebase/analytics'
import {getPerformance} from 'firebase/performance'
import {firebaseConfig} from '@constant/config'

const app = initializeApp(firebaseConfig)
const isProduction = process.env.NODE_ENV === 'production'
isSupported().then(supported => {
    if (!supported || !isProduction) return
    const analytics = getAnalytics(app)
    getPerformance(app)
    logEvent(analytics, 'initialized_firebase')
})
