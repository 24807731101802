import React from 'react'
import Head from 'next/head'
import {isNotEmpty} from '@util/strings'
import {IMetaTags} from '@component/layouts/PageConfig/PageConfig'
import {useTranslation} from 'next-i18next'

interface IProps {
    className?: string
    metaTags?: IMetaTags
}

const HeadContainer: React.FC<IProps> = ({className, metaTags}) => {
    const {t} = useTranslation()
    return (
        <Head>
            <meta property="og:title" content={isNotEmpty(metaTags?.title) ? metaTags?.title : t('metaTag.title')} />
            <meta
                name="description"
                content={isNotEmpty(metaTags?.description) ? metaTags?.description : t('metaTag.description')}
                key={'desc'}
            />
            <meta
                property="og:description"
                content={isNotEmpty(metaTags?.description) ? metaTags?.description : t('metaTag.description')}
            />
            <meta
                property="og:image"
                content={
                    isNotEmpty(metaTags?.image)
                        ? metaTags?.image
                        : 'https://leagueoftraders.io/images/img_meta_thumbnail.png'
                }
            />
            <meta
                itemProp={'image'}
                content={
                    isNotEmpty(metaTags?.image)
                        ? metaTags?.image
                        : 'https://leagueoftraders.io/images/img_meta_thumbnail.png'
                }
            />

            <meta
                name="thumbnail"
                content={
                    isNotEmpty(metaTags?.image)
                        ? metaTags?.image
                        : 'https://leagueoftraders.io/images/img_meta_thumbnail.png'
                }
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@leagueoftraders_io" />
            <meta name="twitter:creator" content="@leagueoftraders_io" />
            <meta name="twitter:title" content={isNotEmpty(metaTags?.title) ? metaTags?.title : t('metaTag.title')} />
            <meta
                name="twitter:description"
                content={isNotEmpty(metaTags?.description) ? metaTags?.description : t('metaTag.description')}
            />
            <meta
                name="twitter:image"
                content={
                    isNotEmpty(metaTags?.image)
                        ? metaTags?.image
                        : 'https://leagueoftraders.io/images/img_meta_thumbnail.png'
                }
            />
        </Head>
    )
}
export default HeadContainer
