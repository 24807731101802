import {useEffect} from 'react'
import {connect, ConnectionOptions, JetStreamPublishOptions, NatsConnection, Payload} from 'nats.ws'
import {getCookie} from '@util/cookie'
import useSnackbar from '@hook/useSnackbar'
import {useLoginStore} from '@store/LoginStore'
import {shallow} from 'zustand/shallow'
import {createLog} from '@util/logs'
import {showToast} from '@component/snackbar/WizToastMessage'

const JETSTREAM_SERVERS = [
    'wss://stream1.leagueoftraders.io:8888',
    'wss://stream2.leagueoftraders.io:8888',
    'wss://stream3.leagueoftraders.io:8888',
]
export const ErrorNoConnection = new Error('No connection to NATS')

export const NatsSubjects = {
    Order: 'order.',
} as const
export type NatsSubjects = (typeof NatsSubjects)[keyof typeof NatsSubjects]

export class NatsClient {
    private static instance: NatsClient
    private static natsConn: NatsConnection | null = null

    private constructor() {}

    public static getInstance(): NatsClient {
        if (!NatsClient.instance) {
            NatsClient.instance = new NatsClient()
        }
        return NatsClient.instance
    }

    public async connectToNats(
        id: string,
        token: string,
        action?: (text: string) => void,
        options?: ConnectionOptions,
    ) {
        if (NatsClient.natsConn && !NatsClient.natsConn.isClosed()) {
            createLog('error', 'error_nats_connection_not_closed')
            return
        }
        try {
            NatsClient.natsConn = await connect({
                ...options,
                token: token,
                servers: JETSTREAM_SERVERS,
            })
            // https://natsbyexample.com/examples/messaging/pub-sub/deno
            if (!NatsClient.natsConn) return
            await this.handleOrder(action)
        } catch (e) {
            console.error('Error', e)
            this.closeNats()
        }
    }

    async handleOrder(action?: (text: string) => void) {
        if (!NatsClient.natsConn) return

        // const sub = NatsClient.natsConn.subscribe('orders.>')
        const sub = NatsClient.natsConn.subscribe(`user.${getCookie('userId')}.notifications.>`)
        for await (const msg of sub) {
            action(JSON.parse(Buffer.from(msg.data).toString('utf-8'))?.message)
        }
    }

    public async publishMessage(subject: NatsSubjects, data: any, options?: JetStreamPublishOptions) {
        if (!NatsClient.natsConn || NatsClient.natsConn.isClosed()) {
            throw ErrorNoConnection
        }
        const payload: Payload = JSON.stringify(data)
        NatsClient.natsConn.publish('subject', payload)
    }

    public closeNats() {
        NatsClient.natsConn?.drain()
        NatsClient.natsConn?.close()
        NatsClient.natsConn = null
    }
}

//called once to initialize the connection
export const useNats = async () => {
    const {showSnackbar} = useSnackbar()
    const {id, accessToken} = useLoginStore(
        state => ({
            id: state.id,
            accessToken: state.accessToken,
        }),
        shallow,
    )
    useEffect(() => {
        const natsClient = NatsClient.getInstance()
        natsClient.closeNats()

        if (id) {
            natsClient.connectToNats(id, accessToken, text => {
                showToast(text)
            })
            return () => {
                natsClient.closeNats()
            }
        }
    }, [id])
}
