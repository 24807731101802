import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, useImperativeHandle, useState} from 'react'
import LoginModalRouter from '@feature/common/components/LoginModal/LoginModalRouter'
import Head from 'next/head'

interface IProps {
    onClose?: () => void
}

const LoginModal = ({onClose}: IProps, ref: any) => {
    const [isOpen, setIsOpen] = useState(false)

    useImperativeHandle(ref, () => ({
        show: () => {
            showModal()
        },
        close: () => {
            closeModal()
        },
    }))

    const closeModal = () => {
        setIsOpen(false)
        onClose && onClose()
    }

    const showModal = () => {
        setIsOpen(true)
    }

    const getPanelStyle = () => {
        const widthStyle = `flex sm:block w-full max-w-[800px] sm:w-full sm:max-w-[600px]`
        const heightStyle = `max-h-[564px] flex-1 sm:max-h-full sm:flex-1 overflow-y-auto`
        const paddingStyle = ''
        const shadowStyle = 'shadow-xl sm:shadow-none'

        return `${widthStyle} ${heightStyle} ${paddingStyle} ${shadowStyle}`
    }

    return (
        <>
            <Head>
                <meta name="appleid-signin-client-id" content="com.wizpace.lot.web" />
                <meta name="appleid-signin-scope" content="name email" />
                <meta name="appleid-signin-redirect-uri" content={`https://leagueoftraders.io/api/login/apple`} />
            </Head>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-modal" unmount={true} onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-[3px]" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className={`flex flex-col min-h-full items-center justify-center`}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95">
                                <Dialog.Panel
                                    className={`${getPanelStyle()} overflow-hidden rounded-[5px] sm:rounded-[0px] sm:bg-white dark:sm:bg-bg_dark_popup`}>
                                    <LoginModalRouter closeModal={closeModal} />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default React.forwardRef(LoginModal)
