import * as amplitude from '@amplitude/analytics-browser'
import {IMe} from '@type/profile'
const identifyEvent = new amplitude.Identify()
export function setUserMeProperties(me?: IMe) {
    if (!me) return
    identifyEvent.set('name', me.name)
    identifyEvent.set('phone_confirmed', me.phone_confirmed)
    identifyEvent.set('email_confirmed', me.email_confirmed)
    identifyEvent.set('thumbnail', me.thumbnail)
    identifyEvent.set('followers', me.followers)
    identifyEvent.set('following', me.following)
    identifyEvent.set('active_exchange', me?.active_exchanges)
    identifyEvent.set('active_exchange_count', me?.active_exchanges?.length || 0)
    identifyEvent.set('created_at', me.created_at)
    identifyEvent.set('privacy_level', me?.privacy_level)
    identifyEvent.set('change_count', me?.change_count)
    identifyEvent.set('flags', me?.flags)
    identifyEvent.set('permission', me?.permissions)
    identifyEvent.set('key_count', me?.keys?.length || 0)
    identifyEvent.set('login_type', me?.network || 'email')

    amplitude.identify(identifyEvent)
}

export function setUserProperty(key: string, value: any) {
    identifyEvent.setOnce(key, value)
    amplitude.identify(identifyEvent)
}

export function setLogUserLogout() {
    identifyEvent.clearAll()
    amplitude.identify(identifyEvent)
}
