import React, {useImperativeHandle, useMemo, useRef, useState} from 'react'
import WizPopupModal from '@component/modal/WizPopupModal'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import {useRouter} from 'next/router'
import Span from '@component/Text/Span'
import useFormatNumber from '@hook/useFormatNumber'
import {getNumberColorClass} from '@util/etc'
import img_logo from '@image/lot_logo.png'
import WizImage from '@component/images/WizImage'
import WizButton from '@component/buttons/WizButton'
import useNavigation from '@hook/useNavigation'
import {domToJpeg} from 'modern-screenshot'
import useFormatDate from '@hook/useFormatDate'
import useSnackbar from '@hook/useSnackbar'
import useQueryGetTodayDailyReport from '@hook/query/user/account/report/useQueryGetTodayDailyReport'
import useDark from '@hook/useDark'
import img_logo_dark from '@image/lot_logo_white.png'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
}

const FirstJoinDailyReportModal = ({className}: IProps, ref: any) => {
    const {t} = useTranslation()
    const {displayBalance, displayPercent, displayNumber} = useFormatNumber()
    const {goUserPortfolio} = useNavigation()
    const {formattedUTCOffset} = useFormatDate()
    const {showSnackbar} = useSnackbar()
    const modalRef = useRef<any>()
    const divRef = useRef<any>()
    const router = useRouter()
    const {data} = useQueryGetTodayDailyReport(false)
    const {isRenderDark} = useDark()
    const [isLoading, setIsLoading] = useState(true)

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        },
    }))

    const getNumberHighLightColor = useMemo(() => {
        if (
            Number(
                displayBalance(data?.earning, {
                    showPreSign: false,
                    isShowMinusSymbol: false,
                }),
            ) === 0
        ) {
            return 'bg-gray07 dark:bg-dark_gray07'
        }
        if (data?.earning >= 0) {
            return 'bg-primary_tint01 dark:bg-dark_primary_tint01'
        } else {
            return 'bg-red_tint01 dark:bg-dark_red_tint01'
        }
    }, [data, data])

    const onClickSaveDailyReport = async () => {
        if (!divRef.current) return

        if (isLoading) {
            setIsLoading(false)
            setTimeout(async () => {
                domToJpeg(divRef.current, {
                    scale: 2,
                    height: divRef?.current?.offsetHeight,
                    width: divRef?.current?.offsetWidth,
                    features: {removeControlCharacter: false},
                }).then(dataUrl => {
                    var link = document.createElement('a')
                    link.download = `Daily Report_${new Date().getTime()}`
                    link.href = dataUrl
                    link.click()
                })
            }, 500)
            setTimeout(() => {
                setIsLoading(true)
            }, 3000)
        }
    }

    const renderUserProfitText = useMemo(() => {
        return `${displayBalance(data?.earning, {
            showPreSign: true,
            isShowMinusSymbol: false,
        })}(${data?.score >= 0 ? '+' : ''}${displayPercent(data?.score)})`
    }, [data, t, data])

    const renderUserProfitDesc = useMemo(() => {
        return data?.message
    }, [data, t, data])

    const renderUserProfitEmoji = useMemo(() => {
        return data?.emoji
    }, [data, t])

    const getTextLengthFontSize = useMemo(() => {
        if (renderUserProfitText?.length > 24) {
            return 'text-landing_display2 font-bold'
        } else {
            return 'text-landing_display1'
        }
    }, [renderUserProfitText])

    return (
        <WizPopupModal title={t('dailyReport.title')} titleClassName={'text-h4'} ref={modalRef}>
            {Number(
                displayBalance(data?.earning, {
                    showPreSign: false,
                    isShowMinusSymbol: false,
                }),
            ) === 0 ? (
                <div
                    ref={divRef}
                    className={'pt-[5px] px-[10px] pb-[20px] sm:pb-[15px] font-landing bg-white dark:bg-bg_dark_popup'}>
                    <div className={'pt-[10px]'}>
                        <Text className={'text-[50px] leading-[50px] text-center'}>💡</Text>
                    </div>
                    <Text
                        className={
                            'text-landing_body_small sm:text-landing_body_caption text-gray02 dark:text-dark_gray02 text-center mt-[5px]'
                        }>
                        {t('dailyReport.date', {date: formattedUTCOffset(data?.created_at)})}
                    </Text>
                    <div className={'text-center sm:mt-[5px]'}>
                        <Span
                            className={`${getTextLengthFontSize} text-gray01 dark:text-dark_gray01 text-[29px] leading-[45px] font-bold sm:text-h4`}>
                            {t('dailyReport.mainText.same')}
                        </Span>

                        <div className={'inline-block relative ml-[5px]'}>
                            <div
                                className={
                                    'absolute w-full h-[15px] sm:h-[10px] bottom-[6px] sm:bottom-[3px] left-0 rounded-[40px] bg-gray07 dark:bg-dark_gray07'
                                }
                            />
                            <Span
                                className={
                                    'relative text-[29px] text-gray01 dark:text-dark_gray01 leading-[45px] font-bold sm:text-h4'
                                }>
                                ({displayBalance(0, {showPreSign: true})}, {displayPercent(0)})
                            </Span>
                        </div>
                    </div>
                    <div className={'mt-[10px]'}>
                        <Text
                            className={
                                'text-landing_body_small sm:text-landing_body_caption text-gray02 dark:text-dark_gray02 text-center'
                            }>
                            {t('dailyReport.mainText.same.desc')}
                        </Text>
                    </div>
                    <div
                        className={
                            'mt-[15px] bg-gray09 dark:bg-bg_dark_white02 rounded-[5px] py-[15px] px-[20px] space-y-[10px]'
                        }>
                        <div className={'flex items-center justify-between'}>
                            <Text
                                className={
                                    'text-landing_body_small sm:text-landing_body_caption text-gray02 dark:text-dark_gray02 flex-1'
                                }>
                                {t('dailyReport.totalValue')}
                            </Text>
                            <Text
                                className={
                                    'text-landing_body_small sm:text-landing_body_caption text-gray02 dark:text-dark_gray02 '
                                }>
                                {displayBalance(data?.balance, {showPreSign: true})}
                            </Text>
                        </div>
                        <div className={'flex items-center justify-between'}>
                            <Text
                                className={
                                    'text-landing_body_small sm:text-landing_body_caption text-gray02 dark:text-dark_gray02 flex-1'
                                }>
                                {t('dailyReport.earnings')}
                            </Text>
                            <Text
                                className={`text-landing_body_small text-gray02 dark:text-dark_gray02 sm:text-landing_body_caption font-bold sm:font-bold `}>
                                {displayBalance(0, {showPreSign: true})}({displayPercent(0)})
                            </Text>
                        </div>
                    </div>
                    <div className={'mt-[15px]'}>
                        <WizImage
                            src={isRenderDark ? img_logo_dark : img_logo}
                            containerClassName={'w-[150px] mx-auto'}
                        />
                    </div>
                </div>
            ) : (
                <div
                    ref={divRef}
                    className={'pt-[5px] px-[10px] pb-[20px] sm:pb-[15px] font-landing bg-white dark:bg-bg_dark_popup'}>
                    <div className={'pt-[10px]'}>
                        <Text className={'text-[50px] leading-[50px] text-center'}>{`${renderUserProfitEmoji}`}</Text>
                    </div>
                    <Text
                        className={
                            'text-landing_body_small sm:text-landing_body_caption text-gray02 dark:text-dark_gray02 text-center mt-[5px]'
                        }>
                        {t('dailyReport.date', {date: formattedUTCOffset(data?.created_at)})}
                    </Text>
                    <div className={'text-center sm:mt-[5px]'}>
                        {router?.locale === 'en' ? (
                            <>
                                <div className={'inline-block relative mr-[3px]'}>
                                    <div
                                        className={`absolute w-full h-[15px] sm:h-[10px] bottom-[6px] sm:bottom-[3px] left-0 rounded-[40px] ${getNumberHighLightColor}`}
                                    />
                                    <Span
                                        className={`${getTextLengthFontSize} sm:text-h4 relative ${getNumberColorClass(
                                            data?.earning,
                                        )}`}>
                                        {renderUserProfitText}
                                    </Span>
                                </div>
                                <Span
                                    className={`${getTextLengthFontSize} sm:text-h4 text-gray01 dark:text-dark_gray01`}>
                                    {data?.earning >= 0
                                        ? t('dailyReport.mainText.en.increase')
                                        : t('dailyReport.mainText.en.decrease')}
                                </Span>
                            </>
                        ) : (
                            <>
                                <Span
                                    className={`${getTextLengthFontSize} sm:text-h4 block text-gray01 dark:text-dark_gray01`}>
                                    {t('dailyReport.mainText.ko.text1')}
                                </Span>
                                <div className={'inline-block relative mr-[3px]'}>
                                    <div
                                        className={`absolute w-full h-[15px] sm:h-[10px] bottom-[6px] sm:bottom-[3px] left-0 rounded-[40px] ${getNumberHighLightColor}`}
                                    />
                                    <Span
                                        className={`${getTextLengthFontSize} sm:text-h4 relative ${getNumberColorClass(
                                            data?.earning,
                                        )}`}>
                                        {renderUserProfitText}
                                    </Span>
                                </div>
                                <Span
                                    className={`${
                                        renderUserProfitText?.length > 24
                                            ? 'text-landing_display2 font-bold'
                                            : 'text-landing_display1'
                                    } sm:text-h4 text-gray01 dark:text-dark_gray01`}>
                                    {data?.earning >= 0
                                        ? t('dailyReport.mainText.ko.text2.plus')
                                        : t('dailyReport.mainText.ko.text2.minus')}
                                </Span>
                            </>
                        )}
                    </div>
                    <div className={'mt-[10px]'}>
                        <Text
                            className={
                                'text-landing_body_small sm:text-landing_body_caption text-gray02 dark:text-dark_gray02 text-center'
                            }>
                            {renderUserProfitDesc}
                        </Text>
                    </div>
                    <div
                        className={
                            'mt-[15px] bg-gray09 dark:bg-bg_dark_white02 rounded-[5px] py-[15px] px-[20px] space-y-[10px]'
                        }>
                        <div className={'flex items-center justify-between'}>
                            <Text
                                className={
                                    'text-landing_body_small sm:text-landing_body_caption text-gray02 dark:text-dark_gray02 flex-1'
                                }>
                                {t('dailyReport.totalValue')}
                            </Text>
                            <Text
                                className={
                                    'text-landing_body_small sm:text-landing_body_caption text-gray02 dark:text-dark_gray02 '
                                }>
                                {displayBalance(data?.balance, {showPreSign: true})}
                            </Text>
                        </div>
                        <div className={'flex items-center justify-between'}>
                            <Text
                                className={
                                    'text-landing_body_small sm:text-landing_body_caption text-gray02 dark:text-dark_gray02 flex-1'
                                }>
                                {t('dailyReport.earnings')}
                            </Text>
                            <Text
                                className={`text-landing_body_small sm:text-landing_body_caption font-bold sm:font-bold ${getNumberColorClass(
                                    data?.earning,
                                )}`}>
                                {displayBalance(data?.earning, {showPreSign: true})}({displayPercent(data?.score)})
                            </Text>
                        </div>
                    </div>
                    <div className={'mt-[15px]'}>
                        <WizImage src={img_logo_dark} containerClassName={'w-[150px] mx-auto hidden dark:block'} />
                        <WizImage src={img_logo} containerClassName={'w-[150px] mx-auto dark:hidden'} />
                    </div>
                </div>
            )}
            <div>
                <WizButton
                    buttonType={'primary'}
                    text={t('dailyReport.button.text')}
                    className={'w-full'}
                    onClick={() => {
                        createLog('event', 'daily_report_check_my_portfolio', {user_id: data.user_id})
                        goUserPortfolio('mine')
                        modalRef?.current?.close()
                    }}
                />
                <div
                    onClick={() => {
                        createLog('event', 'daily_report_save_my_portfolio', {user_id: data.user_id})
                        onClickSaveDailyReport()
                        showSnackbar(t('dailyReport.snackbar.text'))
                    }}
                    className={'pt-[10px] cursor-pointer'}>
                    <Text className={'text-body2 text-gray02 dark:text-dark_gray02 text-center underline'}>
                        {t('dailyReport.save.text')}
                    </Text>
                </div>
            </div>
        </WizPopupModal>
    )
}

export default React.forwardRef(FirstJoinDailyReportModal)
