import {datadogRum} from '@datadog/browser-rum'
import {datadogConfig} from '@constant/config'

if (datadogConfig?.isEnabled) {
    datadogRum.init({
        applicationId: 'e5618f8f-771c-415f-913f-11c697d8f77a',
        clientToken: 'pubc09ac6373c5279b1634698622e5fa073',
        site: 'ap1.datadoghq.com',
        service: 'lot-web',
        version: '0.1.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    })

    datadogRum.startSessionReplayRecording()
} else {
    datadogRum.stopSessionReplayRecording()
}
